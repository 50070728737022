import Vue from 'vue'
import store from '@/store'
import utils from "@/common/utils.js"
import Axios from 'axios'

const myBrowser= function(){
	var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
	var browser;
	if (userAgent.indexOf("Opera") > -1) {
		browser = "Opera"; //判断是否Opera浏览器
	}else if (userAgent.indexOf("Firefox") > -1) {
		browser = "FF";	//判断是否Firefox浏览器
	}else if (userAgent.indexOf("Chrome") > -1) {
		browser = "Chrome";
	}else if (userAgent.indexOf("Safari") > -1) {
		browser = "Safari"; //判断是否Safari浏览器
	} else if(userAgent.lastIndexOf("Trident")>-1){
		browser = "Edge";
	}else{
		browser=userAgent;
	}
	return browser;
}
export {
	myBrowser
}
