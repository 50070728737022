<template>
	<div id="index-wrap">
		<Header></Header>
		<full-page :options="options" ref="fullpage">
			<div class="section" v-for="(item,index) in list" :key="index">
				<div class="videoContainer" v-if="item.videosrc">
					<video class="fullscreenVideo" id="bgVid" data-autoplay autoplay="autoplay" loop="loop"
						 muted="muted">
						<source :src="item.videosrc">
					</video>
				</div>
				<template  v-if="item.iframesrc">
					<img class="image" :src="item.image" v-if="browser=='Edge'" >
					<!-- <img class="image" :src="item.image" v-if="browser=='Edge' || browser=='Safari'" > -->
					<iframe ref="iframe" :src="item.iframesrc" frameborder="0"
						style="width: 100%;height: 100%;" v-else></iframe>
				</template >
				<img class="image" :src="item.image" v-if="item.image">
				<div class="btn-wrap animated" :class="index==current?'fadeInDown':''">
					<div class="btn" :class="btnItem.type" v-for="(btnItem,btnIndex) in item.btnList" :key="btnIndex"
						@click="btntap(btnItem.link)">{{btnItem.name}}</div>
				</div>
			</div>
			<div class="section">
			    <img class="image" src="../../assets/images/new/p9.png">
				<div class="foot">
					<Footer />
				</div>
			</div>
		</full-page>
		<div class="iconfont iconfanhui-copy" @click="nextTap" v-if="current<list.length"></div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex'
	import utils from '@/common/utils'
	import Header from '@/components/head2022'
	import Footer from '@/components/foot2022'
	import {myBrowser} from '@/common/app.js';
	export default {
		data() {
			return {
				options: {
					css3: true,
					scrollBar: true,
					afterLoad: this.afterLoad,
				},
				current:0,
				browser: '',
				loop: true,
				iframeWin: {},
				list: [
					{
						image: require("../../assets/images/smart0/smart0.jpg"),
						btnList: [{
								name: '了解更多',
								type: 'reverse',
								link: {
									pathName: 'smart0'
								}
							},
							{
								name: '关注订制',
								type: 'online',
								link: {
									pathName: 'smart0Intention'
								}
							},
						]
					},
					{
						image: require("../../assets/images/new/p1.png"),
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
						]
					},
					// {
					// 	image: require("../../assets/images/new/p3.png"),
					// 	btnList: [{
					// 			name: '立即预定',
					// 			type: 'reverse',
					// 			link: {
					// 				url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=530'
					// 			}
					// 		},
					// 		{
					// 			name: '小院在线',
					// 			type: 'online',
					// 			link: {
					// 				url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=530'
					// 			}
					// 		},
					// 		{
					// 			name: '了解更多',
					// 			type: 'more',
					// 			link: {
					// 				url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=530'
					// 			}
					// 		},
					// 	]
					// },
					{
						iframesrc: 'https://app.modaiyun.com/damf/rendering-viewer/pano/LxXcz7uWsx?isRoaming=true',
						btnList: [{
								name: '了解更多',
								type: 'reverse',
								link: {
									pathName: 'smart0'
								}
							},
							{
								name: '关注订制',
								type: 'online',
								link: {
									pathName: 'smart0Intention'
								}
							},
						]
					},
					{
						videosrc: 'http://v.xiaoyuan.com.cn/af25917fvodtranscq1257793586/e193139e387702299253458735/v.f100030.mp4',
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=529'
								}
							},
						]
					},
					{
						iframesrc: ' https://www.kujiale.com/xiaoguotu/pano/3FNY1P5ODAYF',
						image: require("../../assets/images/new/p11.png"),
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=452'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=452'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=452'
								}
							},
						]
					},
					{
						iframesrc: 'https://yun.kujiale.com/cloud/design/3FO437CCRGRY/show',
						image: require("../../assets/images/new/p12.png"),
						btnList: [{
								name: '立即预定',
								type: 'reverse',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=512'
								}
							},
							{
								name: '小院在线',
								type: 'online',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=512'
								}
							},
							{
								name: '了解更多',
								type: 'more',
								link: {
									url: 'http://mall.xiaoyuan.com.cn/index.php?ctl=deal&act=512'
								}
							},
						]
					},
					{
						image: require("../../assets/images/new/p4.png"),
						btnList: [{
							name: '小院在线',
							type: 'online',
							link: {
								url: 'http://mall.xiaoyuan.com.cn'
							}
						}, ]
					},
					{
						image: require("../../assets/images/new/p5.png"),
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'productLifehall'
							}
						}, ]
					},
					{
						image: require("../../assets/images/new/p6.png"),
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'productLifehall'
							}
						}, ]
					},
					{
						image: require("../../assets/images/new/p7.png"),
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'productLifehall'
							}
						}, ]
					},
					{
						image: require("../../assets/images/new/p8.png"),
						btnList: [{
							name: '了解更多',
							type: 'more',
							link: {
								pathName: 'productLifehall'
							}
						}, ]
					},
				]
			}
		},
		components: {
			Header,
			Footer
		},
		computed: {
			...mapState(['ocApi', 'newsApi']),
		},
		watch: {
			// 监听事件，表单页面关闭时结束视频播放
			'visible': function(val) {
				if (this.visible === false) { //visible为表单显示状态
					let myVideo = document.getElementById('video') //对应video标签的ID
					myVideo.pause()
				}
			}
		},
		created() {
			this.browser=myBrowser();
		},
		activated(){
			this.$refs.fullpage.init();
		}, 
		mounted() {
			// 在外部vue的window上添加postMessage的监听，并且绑定处理函数handleMessage
			if(this.browser!='Edge' || this.browser!='Safari'){
				window.addEventListener('message', event => {
					// 根据上面制定的结构来解析iframe内部发回来的数据
					const data = event.data
					switch (data.cmd) {
						case 'returnFormJson':
							// 业务逻辑
							break
						case 'returnHeight':
							// 业务逻辑
							break
					}
				});
				this.iframeWin = this.$refs.iframe.contentWindow
			}
		},
		beforeRouteLeave (to, from, next) {
		   this.$refs.fullpage.destroy('all');
		    next();
		},
		methods: {
			nextTap(){
				fullpage_api.moveSectionDown();
			},
			btntap: utils.debounce(function(e) {
				if (e.url) {
					window.open(e.url)
					return;
				}
			
				if (e.pathName == this.$route.name) {
					this.reload()
				}
				if (e.pathName) {
					this.$router.push({
						name: e.pathName
					});
				}
			}, 200, true),
			sendMessage() {
				// 外部vue向iframe内部传数据
				this.iframeWin.postMessage({
					cmd: 'getFormJson',
					params: {}
				}, '*')
			},
			afterLoad: function(origin, destination, direction) {
				this.current = destination.index;
			},
			navItemClick: function(e) {
				var that = this;
				if (that.navList[e].url) {
					window.location.href = that.navList[e].url
				}
				if (that.navList[e].pathName) {
					that.$router.push({
						name: that.navList[e].pathName
					})
				}
			},
		}
	}
</script>

<style lang="scss" scoped>
	.foot{
		position: absolute;
		bottom: 0;
		width: 100%;
	}
	.videoContainer {
		width: 100%;
		height: 100%;
		overflow: hidden;
		z-index: -100;
		background: url(../../assets/images/new/p10.png) no-repeat;
		background-size: 100% 100%;
	}
	
	#bgVid {
		object-fit: cover;
		height: 100%;
		width: 100%;
		z-index: -90;
	}
	.videoContainer:before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		display: block;
		z-index: -1;
		top: 0;
		left: 0;
		background: rgba(25, 29, 34, .65);
	}
	.iconfont{
		bottom: 26px;
		z-index: 9;
		text-align: center;
		width: 100%;
		color:#FFFFFF;
		font-weight: bold;
		position: absolute;
		&.iconfanhui-copy{
			position: fixed;
		}
	}
	
	#index-wrap {
		overflow-x: hidden;
		overflow-y: hidden;
		.section {
			position: relative;
			.image-wrap{
				height: 100%;
				width: 100%;
			}
			.el-image__inner--center {
				top: 0 !important;
				left: 0 !important;
				transform: translate(0%, 0%) !important;
			}
			.image {
				width: 100%;
				object-fit: cover;
				height: 100%;
			}
			.btn-wrap {
				position: absolute;
				bottom: 41px;
				width: 100%;
				.btn {
					width: 232px;
					height: 32rpx;
					border-radius: 16px;
					font-size: 12px;
					line-height: 32px;
					text-align: center;
					cursor: pointer;
					margin: 19px auto;
				
					&.online {
						background: #222222;
						color: #FFFFFF;
					}
				
					&.more {
						background: #FFFFFF;
						color: #393C41;
					}
				
					&.reverse {
						background: #FFFFFF;
						color: #393C41;
					}
				}
			}
		}
	
	}
</style>
